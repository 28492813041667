@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto+Slab:wght@100..900&display=swap');
@import '../scss/variables';

// **** Prime React CSS **** //
@import 'primereact/resources/themes/lara-light-indigo/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.scss';

// **** Global CSS **** //
body {
    margin: 0px;
    padding: 0px;
    font-family: $font-base;
    &::-webkit-scrollbar {
        width:10px;
    }
    &::-webkit-scrollbar-track {
        background: $bg-40;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: $text-20;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: $text-20; 
    }
}
a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
*{
    &:focus{
        box-shadow: none !important;
        outline: none !important;
    }
}
.p-component{
    font-family: $font-base;
}
.hr-line{
    height: 1px;
    background: #E6EAE7;
    margin: 15px 0px;
}

.error-message{
    color: $primary-70;
    font-size: 12px;
    font-weight: 400;
}
.error-message::first-letter {
    text-transform: uppercase;
  }
.info-message{
    font-size: 12px;
    color: $blue-70;
    border: 1px solid #c7d1e3;
    display: inline-flex;
    padding: 4px 5px;
    border-radius: 4px;
    .pi-info-circle{
        font-size: 14px;
    }
}
.description-textarea {
    width: 100%;
    resize: none;
    padding: 10px 14px;
    border-radius: 6px;
    border: 1px solid $bg-40;
    background: $bg-20;
    min-height: 145px;
    font-size: 13px;
    color: $text-30;
    font-weight: 400;
}
.primay-btn{
    background: #ffffff;
    padding: 6px 10px 6px 8px;
    border-radius: 4px;
    border: 1px solid #D1DCD3;
    &:hover{
        background-color: #151518 !important;
        border-color: #151518 !important;
        span{color: white !important;}
    }
}

// **** Header CSS **** //
.header-section{
    .top-header{
        background: $white;
        border-radius: 0px;
        border: none;
        border-bottom: 1px solid #DADADF;
        padding: 20px 70px;
        .logo-img{
            img{
                width: 100%;
                max-width: 85px;
            }
        }
        .back-btn{
            color: #6E6E77;
            font-size: 13px;
            gap: 8px;
            cursor: pointer;
            .pi-arrow-left{
                font-size: 10px;
                color: #ADADB7;
            }
        }
        
        .right-icons{
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 500;
            color: $text-30;
            .pi{
                color: $text-30;
                margin-right: 8px;
            }
        }
        .line{
            background: $secondary-40;
            width: 2px;
            height: 30px;
        }
        .drop-down-button{
            background: transparent;
            border: none;
            padding: 0px;
            width: 2rem;
            cursor: pointer;
            .p-button-icon{
                color: $text-20;
                font-size: 14px;
            }
            &:focus{
                box-shadow: none;
            }
        }
        .user-button{
            height: 2rem;
            width: 2rem;
            border-color: $text-20;
            .p-button-icon{
                font-size: 15px;
            }
            .user-icon{
                line-height: 12px;
            }
        }
        .create-button{
            padding: 8px 12px;
            border-radius: 4px;
            border: none;
            background: $blue-70;
            .p-button-label{
                font-size: 13px;
                font-weight: 500;
            }
            &:hover{
                background: $text-40;
            }
        }
        .menu-bars-button{
            width: 32px;
            height: 32px;
            padding: 2px 10px;
            border-radius: 4px;
            background: $bg-20;
            border: none;
            .p-button-icon{
                color: $text-60;
                font-weight: 500;
            }
            &:hover{
                background: $blue-70;
                .p-button-icon{
                    color: $white;
                }
            }
        }
    }
}
.p-datatable .p-sortable-column.p-highlight{
    background: #f7f7f7 !important;
}
.p-datatable .p-datatable-tbody > tr{
    background: #F9FAFB;
}
/* .p-overflow-hidden {
    overflow: auto;
} */

// **** Menu Bar CSS **** //
.menu-section{
    padding: 19px 70px;
    border-bottom: 1px solid $bg-40;
    background: $white;
    .p-menubar{
        display: inline-flex;
        background: $bg-30;
        padding: 6px 6px 7px;
        border-radius: 4px;
        border: 1px solid #EFF4F0;
        .p-menubar-root-list{
            .p-menuitem{
                .p-menuitem-link{
                    padding: 8px 16px;
                    font-size: 13px;
                    font-weight: 500;
                    color: $text-60;
                    border: none;
                    border-radius: 4px;
                    background: $bg-30;
                    border: 1px solid $bg-30;
                    cursor: pointer;
                    &:hover{
                        background: $white;
                        border: 1px solid $primary-50;
                        box-shadow: 2px 4px 18px 0px rgba(111, 110, 151, 0.15);
                    }
                }
                &.p-menuitem-active{
                    .p-menuitem-link{
                        background: $white;
                        border: 1px solid $primary-50;
                        box-shadow: 2px 4px 18px 0px rgba(111, 110, 151, 0.15);
                        .p-menuitem-text{
                            color: $text-60;
                        }
                        &:hover{
                            background: $white;
                            border: 1px solid $primary-50;
                            box-shadow: 2px 4px 18px 0px rgba(111, 110, 151, 0.15);
                            .p-menuitem-text{
                                color: $text-60;
                            }
                        }
                    }
                }
            }
        }
    }
    .back-link{
        font-size: 13px;
        font-weight: 400;
        color: $text-30;
        padding: 15px 0px;
        display: block;
        .pi{
            font-size: 12px;
            margin-right: 5px;
        }
        &:hover{
            color: $text-40;
        }
    }
}
.p-menu.p-menu-overlay{
    margin-top: 30px;
    padding: 10px;
    width: auto;
    z-index: 9999 !important;
    .p-menu-list{
        .p-menuitem{
            .p-menuitem-content{
                border-radius: 4px;
                background: transparent;
            }
            .p-menuitem-link{
                border-radius: 4px;
                .p-menuitem-text{
                    font-size: 13px;
                    font-weight: 400;
                    color: $text-40;
                }
                .p-menuitem-icon{
                    color: $text-30;
                    font-weight: 600;
                    font-size: 14px;
                }
                .menu-svg-icon {
                    margin-right: 0.5rem;
                    height: 15px;
                    width: 14px;
                    line-height: 15px;
                }
                &:hover{
                    background: $bg-20;
                }
            }
        }
    }
}
.p-datepicker{
    .p-datepicker-header{
        justify-content: center;
        border-bottom: 1px solid #f5f5f5;
        .p-datepicker-title{
            margin: 0px;
           .p-link{
                font-size: 16px;
                font-family: $font-base;
                font-weight: 500;
                padding: 0.5rem 0.2rem;
                &:hover{
                    color: $primary-60 !important;
                }
           }
        }
    }
    table td{
        padding: 0.3rem;
    }
    table th > span{
        color: #333;
        font-size: 12px;
        font-weight: 600;
    }
    table td > span{
        font-size: 12px;
        font-weight: 500;
        width: 25px;
        height: 25px;
        &:hover{
            background: $primary-60 !important;
            color: $white !important;
        }
        &.p-highlight{
            background: $primary-60 !important;
            color: $white !important;
        }
    }
    table td.p-datepicker-today > span{
        background: $primary-30;
        color: $white;
    }
    .p-timepicker{
        padding: 4px 0px 0px;
        border-top: 1px solid #f5f5f5;
        span{
            font-size: 12px;
            font-weight: 500;
        }
        .p-link{
            height: 20px;
            width: 20px;
            .p-icon{
                width: 12px;
            }
        }
    }
    .p-datepicker-buttonbar{
        padding: 0px 0;
        border-top: 1px solid #f5f5f5;
        .p-button{
            padding: 5px 10px;
            margin: 4px 0px;
            .p-button-label{
                font-size: 12px;
            }
        }
    }
}



// **** Logout Dialog CSS **** //
.logout-dialog{
    width: 100%;
    max-width: 275px;
    .p-dialog-header{
        display: none;
    }
    .p-dialog-content{
        border-radius: 6px 6px 0px 0px;
        border-bottom: 1px solid #EAECF0;
        padding: 1.2rem 1.5rem;
        .logout-section{
            h2{
                font-size: 20px;
                font-weight: 500;
                margin: 0px 0px 10px;
                color: $text-40;
                font-family: $font-secondary;
            }
            p{
                margin: 0px;
                font-size: 14px;
                font-weight: 400;
                color: $text-30;
            }
            label{
                font-size: 13px;
                font-weight: 400;
                color: $text-40;
            }
        }
        &::-webkit-scrollbar {
            width:6px;
        }
        &::-webkit-scrollbar-track {
            background: $bg-20;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: $bg-40;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: $text-20; 
        }
       
    }
    .p-dialog-footer{
        padding: 1rem 20px;
        .logout-btns{
            padding: 8px 14px;
            border-radius: 4px;
            border: 1px solid $bg-40;
            background: $white;
            .p-button-label{
                font-size: 13px;
                font-weight: 500;
                color: $text-40;
            }
            &.click-btn{
                background: $primary-60;
                border: 1px solid $primary-60;
                &.regulator{
                    background: $re-green-70;
                    border: 1px solid $re-green-70;
                }
                .p-button-label{
                    color: $white;
                }
                &:hover{
                    background: $gray-900;
                    border: 1px solid $gray-900;
                }
                .p-button-icon{
                    color: $white;
                    font-size: 12px;
                }
            }
            &:hover{
                border: 1px solid $text-40;
                background: $text-60;
                .p-button-label{
                    color: $white;
                }
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
}

.chat-dialog{
    .dialog-header{
        position: sticky;
        top: 0px;
        background: $white;
        z-index: 6;
    }
    .p-dialog-header .p-dialog-header-icons{
        z-index: 9;
    }
    .p-dialog-content{
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        &::-webkit-scrollbar-track {
            display: none;
        }
        &::-webkit-scrollbar-thumb {
            display: none;
        }
        &::-webkit-scrollbar-thumb:hover {
            display: none;
        }
    }
}

.alert-search-tooltip{
    .p-tooltip-text{
        font-size: 12px;
        padding: 8px;
    }
}


// **** Footer CSS **** //
footer{
    border-top: 1px solid #DADADF;
    margin-top: 2.5rem;
    .footer-section{
        padding: 25px 70px 20px;
        background: $white;
        p{
            margin: 0px;
            font-size: 13px;
            font-weight: 400;
            color: $text-60;
        }
        a{
            color: $text-60;
            &:hover{
                opacity: 0.5;
            } 
        }
        ul{
            padding:0px;
            margin: 0px;
            color: $text-60;
            li{
                font-size: 13px;
                font-weight: 400;
                margin: 0rem 0.4rem;
                &.line{
                    opacity: 0.3;
                    font-size: 11px;
                } 
            }
        }
    }
}
.login-footer{
    width: 90%;
    margin: 0 auto;
    p{
        font-size: 13px;
        font-weight: 400;
        margin: 0rem 0.4rem;
        a{
            color: $white;
            &:hover{
                opacity: 0.5;
            }
        }
    }
}
.p-toast{
    opacity: 1;
    max-width: 26rem;
    .p-toast-message{
        margin: 0 0 0rem 0;
        &.p-toast-message-success{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 7px;
                top: 6px;
                background: #2BA079;
                border-radius: 2px;
            }
        }
        &.p-toast-message-error{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 7px;
                top: 6px;
                background: #ff5757;
                border-radius: 2px;
            }
        }
        &.p-toast-message-warn{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 7px;
                top: 6px;
                background: #cc8925;
                border-radius: 2px;
            }
        }
        &.p-toast-message-info{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 7px;
                top: 6px;
                background: #5774cd;
                border-radius: 2px;
            }
        }
        .p-toast-message-content{
            padding: 18px;
            padding-left: 25px;
            align-items: center;
            .p-toast-message-text{
                .p-toast-summary{
                    font-size: 14px;
                    font-weight: normal;
                }
                .p-toast-detail{
                    font-size: 15px;
                    margin: 0.4rem 0 0 0;
                    color: #505050;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
        .p-toast-icon-close{
            width: auto;
            height: auto;
            color: #505050 !important;
            font-size: 14px;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 9;
            .p-toast-icon-close-icon{
                font-size: 13px;
                width: 14px;
            }
        }
    }
}

.p-input-icon-right{
    .right-search-icon{
        position: absolute;
        right: 12px;
        top: 7px;
    }
    .right-clear-icon{
        position: absolute;
        right: 0px;
        top: 7px;
    }
    .caseupdates-clear-icon{
        position: absolute;
        right: 0px;
    }
    .regulatorAlert-clear-icon{
        position: absolute;
        right: 0px;
        top: 10px;
    }
}
.search-clear-icon{
    position: absolute;
    right: 0px;
    top: 12px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text{
    margin: 0 0 0 10px;
}


.status{
    font-size: 12px;
    font-weight: 500;
    padding: 3px 8px;
    border-radius: 16px;
    color: $text-40;
    border: 1.5px solid $text-30;
    text-align: center;
    white-space:nowrap;
    &.error{
        border: 1.5px solid $danger-20;
        color: $danger-20;
    }
    &.warning{
        border: 1.5px solid $warning-20;
        color: $warning-20;
    }
    &.success{
        border: 1.5px solid $success-20;
        color: $success-20;
    }
    &.not-started{
        border: 1.5px solid $gray-700;
        color: $gray-700;
    }
}
.group-name-icon{
    line-height: 12px;
    img{
        width: 2rem;
        height: 2rem;
        border-radius: 40px;
        object-fit: cover;
        object-position: center;
    }
}
.p-paginator-bottom{
    .p-dropdown{
        height: 2rem;
        .p-dropdown-label{
            padding: 6px 8px;
            font-size: 14px;
        }
        .p-dropdown-trigger{
            width: 2.5rem;
            svg{
                width: 13px;
            }
        }
    }
    .p-paginator-pages{
        .p-paginator-page{
            font-size: 15px;
            &.p-highlight{
                color: $primary-60;
                font-weight: 600;
                font-size: 15px;
                background: none;
            }
        }
    }
}
// Loading Spinner CSS
.loading{
    position: absolute;
    width: 100%;
    height: calc(100% - 65px);
    background: rgba(227, 227, 227, 0.91);
    left: 0px;
    right: 0px;
    top: 65px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    z-index: 999;
    .pi{
        font-size: 2rem;
        color: $primary-70;
    }
    &.runpage-loading{
        background: transparent;
        height: calc(100% - 500px);
    }
    &.chat-loading{
        max-width: 350px;
        max-height: 350px;
        margin: 0 auto;
        top: 28%;
    }
    &.new-updates-loading{
        position: absolute;
    }
}

.p-datatable .p-datatable-thead > tr > th{
    background: $white;
}
.absolute-loading{
    position: absolute !important;
}
.success-message {
    color: $re-green-70;
    font-size: 14px;
    text-align: center;
}
/* Allow user to drag and select text within the DataTable */
.data-table, 
.data-table * {
  -webkit-user-drag: auto !important;
  user-select: text !important;
  /* Ensure text selection is allowed */
}

/* Additionally, ensure that no parent elements are preventing selection */
/* .card, 
.card * {
  -webkit-user-drag: auto !important;
  user-select: text !important;
} */
.custom-toast .pi{
    font-size: 18px !important; /* Adjust this value to make the icon smaller */
}
.file-download-button{
    background: $bg-20;
    padding: 8px 12px 8px 14px;
    border-radius: 4px;
    border: 1px solid $bg-40;
    display: flex;
    margin-top: 10px;
    .p-button-label{
        font-size: 13px;
        font-weight: 500;
        color: $text-40;
    }
    .p-button-icon{
        color: $text-40;
        font-size: 12px;
    }
    &:hover{
        background: $text-40 !important;
        border: 1px solid $text-40 !important;
        .p-button-label{
            color: $white !important;
        }
        .p-button-icon{
            color: $white !important;
        }
        .pi-times{
            color: $white;
        }
    }
    &:focus{
        box-shadow: none;
    }
    .pi-times{
        color: $text-40;
        font-size: 12px;
        margin-left: 10px;
        position: relative;
        top: 1px;
    }
}
.content-editor {
    .p-editor-toolbar{
        display: none;
    }
}
.content{
    p{
        font-weight: 400;
    }
    ul{
        padding-left: 25px !important;
        margin: 0px 0px 5px !important;
        li{
            padding: 2px 0px;
        }
    }
    ol{
        padding-left: 25px !important;
        li{
            font-size: 13px;
            padding: 2px 0px;
        }
    }
    a{
        color: #0A45A9 !important;
        &:hover{
            text-decoration: underline;
        }
    }
}
.ql-toolbar.ql-snow{
    border: 1px solid #e5e5e5;
    background: #f5f6f9;
    border-radius: 6px 6px 0px 0px;
}