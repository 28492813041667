@import '../../../assets/scss/variables.scss';

// **** All Alerts Tab CSS **** //
th.p-sortable-column.custom-arrow{
    position: relative;
    &:after{
        content: '';
        display: block;
        position: absolute;
        left: 69px;
        top: 16px;
        height: 20px;
        width: 23px;
        background-repeat: no-repeat;
        background-color: #EEF2FF;
    }
    &.asc:after{
        background-image: url('../../../assets/images/asc.svg');
    }    
    &.desc:after{
        background-image: url('../../../assets/images/des.svg');
        // background-color: inherit;
    }
}
.alerts-tab .all-alerts-section .alerts-tab .data-table .p-paginator{
    gap: 10px;
}
.p-paginator .p-paginator-current{
    padding: 0;
    font-size: 12px;
    min-width: initial;
}
.all-alerts-section{
    font-family: $font-base;
    padding: 25px 70px;
    height: calc(100% - 204px);
    min-height: calc(100vh - 204px);
    h1{
        font-size: 20px;
        font-weight: 500;
        color: $text-40;
        font-family: $font-secondary;
        margin: 0px;
    }
    .create-alert{
        position: absolute;
        right: 0px;
        top: -135px;
        .create-button{
            background: $primary-60;
            border-radius: 4px;
            border: none;
            padding: 6px 10px 6px 8px;
            .p-button-label{
                font-size: 13px;
                font-weight: 500;
                color: $white;
            }
            .p-button-icon{
                color: $white;
                font-size: 13px;
            }
            &:hover{
                background: $text-40;
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
    .alerts-tab{
        margin-top: 26px;
        position: relative;
        .p-tabview{
            .p-tabview-nav-container{
                padding: 0px 0px !important;
                border-bottom:none !important;
                .p-tabview-nav-content{
                    .p-tabview-nav{
                        display: inline-flex;
                        align-items: center;
                        background: $bg-30;
                        padding: 7px 7px 9px;
                        border-radius: 4px;
                        border: 1px solid $bg-40 !important;
                        .p-unselectable-text{
                            .p-tabview-nav-link{
                                padding: 8px 16px;
                                font-size: 13px;
                                font-weight: 500;
                                color: $text-60;
                                border: none;
                                border-radius: 4px;
                                background: $bg-30;
                                border: 1px solid $bg-30;
                            }
                            &.p-tabview-selected{ 
                                .p-tabview-nav-link{
                                    background: $white;
                                    border: 1px solid $primary-50;
                                    box-shadow: 2px 4px 18px 0px rgba(111, 110, 151, 0.15);
                                }
                            }
                        }
                        .p-tabview-ink-bar{
                            display: none;
                        }
                        li{
                            .no-required-action {
                                font-size: 13px;
                                font-weight: 500;
                                padding: 0px 16px;
                                cursor: pointer;
                            }
                            &.p-highlight{
                                .no-required-action {
                                    background: $white;
                                    box-shadow: 2px 4px 18px 0px rgba(111, 110, 151, 0.15);
                                    color: $text-60;
                                    border-radius: 4px;
                                    cursor: pointer;
                                    margin-top: 1px;
                                    border: 1px solid #CFD8E3;
                                    .tab-name{
                                        padding: 7px 5px;
                                    }
                                }
                            }
                            &.action-tab{
                                .response-action-tab {
                                    font-size: 13px;
                                    font-weight: 500;
                                    padding: 0px 16px;
                                    color: $text-60;
                                    border-radius: 4px;
                                    background: #EBD8D6;
                                    border: 1px solid #EBD8D6;
                                    cursor: pointer;
                                    line-height: 13px;
                                    margin-top: 1px;
                                    .tab-name{
                                        padding: 7px 5px;
                                    }
                                    .p-badge{
                                        width: 22px;
                                        height: 22px;
                                        background: $danger-20;
                                        font-weight: 500;
                                        line-height: 21px;
                                        display: flex;
                                        justify-content: center;
                                        font-size: 12px;
                                        border-radius: 30px;
                                        align-items: center;
                                    }
                                }
                                &.p-highlight{
                                    .response-action-tab{
                                        border: 1px solid $danger-20;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .p-tabview-panels{
                padding: 0px !important;
                background: transparent;
            }
        }
        .data-table{
            margin-top: 20px;
            .p-datatable-header{
                background: transparent;
                border: none;
                padding: 0rem;
            }
            .p-datatable-wrapper{
                border-radius: 12px 12px 0px 0px;
                border: 1px solid $border-gray;
                background: $white;
                .p-datatable-table{
                    .p-datatable-thead{
                        .p-column-header-content{
                            .p-column-title{
                                font-size: 12px;
                                font-weight: 500;
                                color: $gray-600;
                            }
                            span{
                                line-height: 0px;
                                svg{
                                    width: 10px;
                                    height: 10px;
                                    color: $text-20;
                                }
                            }
                        }
                    }
                    .p-datatable-tbody{
                        tr{
                            td{
                                font-size: 13px;
                                font-weight: 500;
                                color: $gray-900;
                                .response{
                                    border-radius: 16px;
                                    border: 1px solid $bg-40;
                                    background: $white;
                                    font-size: 13px;
                                    font-weight: 500;
                                    color: $text-40;
                                    padding: 2px 6px 2px 6px;
                                    min-width: 55px;
                                    cursor: pointer;
                                    text-align: center;
                                    .pi{
                                        font-size: 10px;
                                        color:$text-30
                                    }
                                    &.suspicious{
                                        color: $danger-20;
                                        .pi{
                                            color: $danger-20;
                                            font-weight: 500;
                                        }
                                    }
                                    &.chat{
                                        position: relative;
                                        width: 25px;
                                        min-width: 25px;
                                        height: 25px;
                                        display: flex;
                                        justify-content: center;
                                    &.active::after{
                                        position: absolute;
                                        content: '';
                                        width: 6px;
                                        height: 6px;
                                        background: $danger-20;
                                        border-radius: 5px;
                                        right: -1px;
                                    }
                                    &.disabled{
                                        opacity: 0.5;
                                        pointer-events: none;
                                        .text-blur{
                                            filter: blur(2px);
                                        }
                                        &.disabled::after{
                                            display: none;
                                        }
                                    }
                                    &.non-clickable{
                                        pointer-events: none;
                                        span{
                                            opacity: 0.5;
                                        }
                                    }
                                    }
                                    &.disableStyle{
                                        opacity: 0.5;
                                        pointer-events: none;
                                        .text-blur{
                                            filter: blur(2px);
                                        }
                                    }
                                }
                                .status{
                                    font-size: 12px;
                                    font-weight: 500;
                                    padding: 3px 8px;
                                    align-items: center;
                                    border-radius: 16px;
                                    display: inline-flex;
                                    border: 1.5px solid $text-30;
                                    &.error{
                                        border: 1.5px solid $danger-20;
                                        color: $danger-20;
                                    }
                                    &.warning{
                                        border: 1.5px solid $warning-20;
                                        color: $warning-20;
                                    }
                                    &.success, &.active{
                                        border: 1.5px solid $success-20;
                                        color: $success-20;
                                    }
                                    &.not-started{
                                        border: 1.5px solid $gray-700;
                                        color: $gray-700;
                                    }
                                }
                                .view-details-button{
                                    padding: 6px 14px;
                                    border-radius: 6px;
                                    border: 1px solid $border-gray;
                                    background:$white;
                                    .p-button-label{
                                        font-size: 12px;
                                        font-weight: 500;
                                        color: $text-40;
                                    }
                                    &:hover{
                                        background: $text-60;
                                        border: 1px solid $text-60;
                                        .p-button-label{
                                            color: $white;
                                        }
                                    }
                                    &.retry-button{
                                        border: 1px solid $danger-border;
                                        background:$danger-10;
                                        &:hover{
                                            background: $danger-30;
                                            border: 1px solid $danger-30;
                                            .p-button-label{
                                                color: $white;
                                            }
                                        }
                                    }
                                }
                                &.last-column{
                                    width: 1%;
                                    white-space:nowrap;
                                    .response-span{
                                        border-radius: 2px;
                                        border: 1px solid rgba(211, 26, 14, 0.26);
                                        background: $secondary-50;
                                        color: $danger-20;
                                        padding: 2px 4px;
                                        font-size: 10px;
                                        margin-top: 5px;
                                        line-height: 0px;
                                        .pi{
                                            font-size: 10px;
                                        }
                                    }
                                }
                                .event-column{
                                    p{
                                        margin: 0px;
                                        padding: 0px;
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: $text-40;
                                    }
                                    span{
                                        font-size: 12px;
                                        font-weight: 400;
                                        color: $text-30;
                                    }
                                }
                                .sports-icon{
                                    line-height: 0;
                                    .p-image{
                                        line-height: 0;
                                        display: block;
                                        border-radius: 20px;
                                    }
                                    img{
                                        width: 30px;
                                        border-radius: 20px;
                                    }
                                }
                                .group-name-block{
                                    .group-name-icon{
                                        line-height: 0px;
                                        img{
                                            width: 36px;
                                            height: 36px;
                                            border-radius: 40px;
                                        }
                                    }
                                    p{
                                        color: $text-40;
                                        font-size: 13px;
                                        font-weight: 500;
                                        
                                        line-height: 160%;
                                        margin-bottom: 2px;
                                    }
                                    .role{
                                        border-radius: 16px;
                                        border: 1px solid $text-20;
                                        background: $bg-20;
                                        padding: 2px 7px;
                                        color: var(--Text-30, #6E6E77);
                                        text-align: center;
                                        font-size: 12px;
                                        font-weight: 500;
                                        line-height: 140%;
                                    }
                                }
                            }
                            &.p-row-odd{
                                background: $white;
                            }
                            &.p-datatable-emptymessage{
                                td{
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
            .p-paginator{
                padding: 18px 24px;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
                border-radius: 0px 0px 12px 12px;
                border: none;
                background: $white;
                margin-top: -1px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: relative;
                gap: 14px;
                .current-page{
                    font-size: 13px;
                    font-weight: 500;
                    color: $gray-700;
                    position: absolute;
                    left: 24px;
                }
                .p-link{
                    padding: 0px;
                    min-width: 20px;
                    width: auto;
                    height: auto;
                    position: relative;
                    border-radius: 0px;
                    margin: 0px 5px;
                    &.p-paginator-prev{
                        // position: absolute;
                        // right: 65px;
                        // top: 22px;
                        &::before{
                            content: "Previous";
                            font-weight: 500;
                            font-size: 14px;
                            color: $text-40;
                            padding: 2px 0px;
                        }
                    }
                    &.p-paginator-next{
                        &::before{
                            content: "Next";
                            font-weight: 500;
                            font-size: 14px;
                            color: $text-40;
                            padding: 2px 0px;
                        }
                    }
                    .p-paginator-icon{
                        display: none;
                    }
                    &:hover{
                        background: none;
                    }
                }
                .pagination-buttons{
                    padding: 0px;
                    background: none;
                    border: none;
                    color: $text-40;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 140%;
                    &:focus{
                        box-shadow:none;
                    }
                    &:hover{
                        color: $blue-70;
                    }
                    &.color-update{
                        &:hover{
                            color: $primary-60;
                        } 
                    }
                }
            }
        }
        .pagination{
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
            border-radius: 0px 0px 12px 12px;
            border: none;
            padding: 20px 1rem;
            background: $white;
            span{
                font-size: 13px;
                font-weight: 500;
                color: $gray-700;
            }
            ul{
                padding: 0px;
                margin: 0px;
                li{
                    text-align: center;
                    cursor: pointer;
                    font-weight: 500;
                    font-size: 12px;
                    color: $text-40;
                    margin: 0px 5px;
                    &.disable{
                        color: $text-30;
                    }
                    &:hover{
                        color: $primary-60;
                    }
                    &.disabled{
                        pointer-events: none;
                        opacity: 0.4;
                    }
                }
            }
        }
        .status-filter-section{
            position: absolute;
            right: 0px;
            top: -59px;
            .search-filters-button{
                padding: 7px 14px 7px 12px;
                border-radius: 6px;
                border: 1px solid $border-gray;
                background:$white;
                .label{
                    font-size: 13px;
                    font-weight: 500;
                    color: $text-40;
                    margin-left: 5px;
                }
                .p-image{
                    line-height: 0px;
                    min-width: 16px;
                    img{
                        position: relative;
                        top: 1px;
                    }
                }
                &:hover{
                    background: $text-40;
                    border: 1px solid $text-40;
                    .label{
                        color: $white;
                    }
                    .p-image{
                        img{
                            filter: invert(1);
                        }
                    }
                }
                &.filter-icon{
                    background-image: url('../../../assets/images/filter-icon.svg');
                    background-repeat: no-repeat;
                    background-position: 12px;
                    padding-left: 1.8rem;
                    &:hover{
                        background-image: url('../../../assets/images/filter-icon-light.svg');
                    }
                }
            }
            .drop-down-button{
                padding: 8px;
                border-radius: 4px;
                border: 1px solid $bg-40;
                background: $white;
                width: 32px;
                height: 32px;
                &.applied-filter{
                    background-color: $success-20;
                    .p-button-icon{
                        color: white;
                    }
                }
                .p-button-icon{
                    color: $text-40;
                    font-size: 15px;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
}

.filter_toggle_icon{
    padding: 8px !important;
    border-radius: 4px;
    border: 1px solid $bg-40 !important;
    background: $white;
    width: 32px !important;
    height: 32px;
    &.applied-filter{
        background-color: $success-20;
        .p-button-icon{
            color: white;
        }
    }
    .p-button-icon{
        color: $text-40;
        font-size: 15px;
        position: relative;
        top: 1px;
    }
    &:focus{
        box-shadow: none;
        outline: none;
    }
}

.filter-applied-section{
    border-radius: 6px;
    border: 1px solid $bg-40;
    background: $bg-30;
    width: 100%;
    padding: 14px 20px 14px 20px;
    margin: 20px 0px;
    hr{
        border-top: 1px solid $bg-40;
        margin: 15px 0px;
    }
    p{
        margin: 0px;
        font-size: 12px;
        font-weight: 400;
        color: $text-40;
    }
    .reset-button{
        background: $white;
        padding: 6px 10px;
        border-radius: 4px;
        border: 1px solid #D9DFE8;
        .p-button-label{
            font-size: 12px;
            font-weight: 500;
            color: $text-40;
        }
        &:hover{
            background: $primary-60;
            border: 1px solid $primary-60;
            .p-button-label{
                color: $white;
            }
        }
    }
    .apply-button{
        background: $primary-60;
        border-radius: 4px;
        border: none;
        padding: 6px 10px;
        .p-button-label{
            font-size: 12px;
            font-weight: 500;
            color: $white;
        }
        .p-button-icon{
            color: $white;
            font-size: 13px;
        }
        &:hover{
            background: $text-40;
        }
        &:focus{
            box-shadow: none;
        }
        &.color-update{
            background: $blue-70;
            &:hover{
                background: $text-40;
            }
        }
    }
    .filters-list{
        font-size: 12px;
        font-weight: 400;
        color: $text-60;
        margin-top: 5px;
        display: block;
        ul{
            margin: 0px;
            padding: 0px;
            li{
                list-style: none;
                display: inline-block;
                span{
                    padding: 6px 10px;
                    border-radius: 4px;
                    border: 1px solid $bg-40;
                    background:$white;
                    font-size: 12px;
                    font-weight: 500;
                    margin-left: 10px;
                    display: inline-block;
                    .pi{
                        font-size: 12px;
                        color: $text-30;
                        position: relative;
                        top: 1px;
                        margin-left: 3px;
                        cursor: pointer;
                        &:hover{
                            color: $primary-60;
                        }
                    }
                }
            }
            &.other-ul{
                li{
                    margin-left: 8px;
                    font-size: 12px;
                    font-weight: 500;
                    color: $text-60;
                    margin-bottom: 8px;
                    &:first-child{
                        margin-left: 0px;
                        font-weight: 400;
                    }
                    span{
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

// Response Dialog
.response-dialog{
    width: 100%;
    max-width: 640px;
    font-family: $font-base;
    .dialog-header{
        h2{
            font-size: 20px;
            font-weight: 500;
            margin: 0px;
            padding: 0px;
            color: $text-40;
            font-family: $font-secondary;
        }
        .suspicious-span{
            font-size: 12px;
            font-weight: 500;
            padding: 3px 8px;
            align-items: center;
            border-radius: 16px;
            display: inline-flex;
            border: 1px solid $text-30;
            background: $bg-20;
        }
        .search-field{
            width: 100%;
            .p-inputtext{
                padding: 10px 12px 10px 35px;
                border-radius: 4px;
                border: 1px solid $bg-40;
                background: $white;
                font-size: 13px;
                color: $text-40;
                width: 100%;
            }
        }
    }
    .dialog-body-section{
        border-radius: 6px;
        margin-top: 1rem;
        .member-response-list{
            border-bottom: 1px solid $bg-40;
            padding: 15px 15px;
            background: $bg-20;
            border-radius: 4px;
            ul{
                margin: 0px;
                padding: 0px;
                li{
                    color: $text-30;
                    font-size: 12px;
                    font-weight: 400;
                    span{
                        color: $text-40;
                        font-size: 13px;
                        font-weight: 500;
                    }
                }
            }
            .icon{
                line-height: 0px;
                img{
                    width: 26px;
                    height: 26px;
                }
            }
            .right{
                span{
                    font-size: 12px;
                    font-weight: 500;
                    padding: 3px 8px;
                    align-items: center;
                    border-radius: 16px;
                    display: inline-flex;
                    border: 1px solid $text-30;
                    background: $white;
                    &.suspicious{
                        border: 1px solid $danger-20;
                        background: $danger-10;
                        color: $danger-20;
                    }
                }
                .pi{
                    font-size: 15px;
                    color: $text-30;
                    margin-left: 12px;
                    cursor: pointer;
                    &:hover{
                        color: $blue-70;
                    }
                }
            }
            &.last{
                border-bottom: none;
            }
        }
        .not-responded-list{
            border-radius: 4px;
            background: $bg-20;
            display: inline-flex;
            align-items: center;
            padding: 6px 12px 6px 8px;
            gap: 6px;
            margin: 10px 10px 0px 0px;
            img{
                width: 26px;
                height: 26px;
            }
            span{
                font-size: 13px;
                color: $text-40;
            }
            .p-image{
                line-height: 0px;
            }
        }
    }
    &.chat-dialog{
        width: 100%;
        max-width: 80%;
        .p-dialog-content{
            padding: 0px;
            .dialog-header{
                padding: 20px;
                .icon{
                    line-height: 0px;
                    img{
                        width: 40px;
                        height: 40px;
                    }
                }
                .chat-header {
                    color: $black;
                    font-weight: 700;
                    .alert-name {
                        color: $gray-600;
                        font-size: 14px;
                        display: flex;
                        width: 400px;
                    }
                }
            }
            .no-message-p{
                font-size: 12px;
                font-weight: 400;
                color: #1F2533;
                padding: 15px 60px;
                text-align: center;
            }
        }
        .p-dialog-header{
            display: block;
            padding: 0px;
            .p-dialog-header-icons{
                position: absolute;
                right: 20px;
                top: 24px;
                .p-dialog-header-icon{
                    background: $bg-20;
                }
            }
        }
        .dialog-body-section{
            border-top: 1px solid $bg-40;
            border-bottom: 1px solid $bg-40;
            background: $bg-20;
            margin-top: 0px;
            padding: 15px 20px;
            border-radius: 0px;
            height: 400px;
            overflow: auto;
            .chat-block-received {
                margin-top: 15px;
                .chat-icon{
                    img{
                        width: 34px;
                        height: 34px;
                    }
                }
                .message-box{
                    width: 80%;
                    p{
                        margin: 0px;
                        padding: 0px;
                        font-size: 13px;
                        font-weight: 400;
                        color: $text-40;
                        line-height: 21px;
                        word-break: break-word;
                    }
                    span{
                        font-size: 12px;
                        font-weight: 400;
                        color: #1F2533;
                    }
                    .message{
                        padding: 14px 16px;
                        border-radius: 0px 12px 12px 12px;
                        background: $white;
                        margin-bottom: 5px;
                        display: inline-block;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 21px;
                    }
                }
                .time{
                    span{
                        font-size: 12px;
                        font-weight: 400;
                        color: #1F2533;
                    }
                }
            }
            .chat-block-sent{
                margin-top: 15px;
                display: flex;
                justify-content: end;
                .message-box{
                    width: 80%;
                    text-align: right;
                    p{
                        margin: 0px;
                        padding: 0px;
                        font-size: 13px;
                        font-weight: 400;
                        color: $text-40;
                        line-height: 21px;
                        text-align: justify;
                        word-break: break-word;
                    }
                    span{
                        font-size: 12px;
                        font-weight: 400;
                        color: #1F2533;
                    }
                    .message{
                        padding: 14px 16px;
                        border-radius: 12px 0px 12px 12px;
                        background: #e9e9e9;
                        margin-bottom: 5px;
                        display: inline-block;
                        margin-left: 3rem;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 21px;
                        text-align: justify;
                    }
                }
            }
            &::-webkit-scrollbar {
                width:6px;
            }
            &::-webkit-scrollbar-track {
                background: $bg-20;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
                background: $bg-40;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: $text-20; 
            }
        }
        .p-dialog-footer{
            .ask-field{
                width: 100%;
                max-width: 75%;
                text-align: left;
            }
            .send-message{
                background: $primary-60;
                border-color: $primary-60;
                .p-button-label{
                    color: $white;
                }
                &:hover{
                    background: $text-40;
                    border-color: $text-40;
                }
            }
        }
    }
    &.filter-dialog{
        width: 100%;
        max-width: 890px;
        .p-dialog-header{
            display: none;
        }
        .p-dialog-content{
            border-radius: 8px;
            padding: 1rem;
            .filter-dialog-body-section{
                .p-tabview{
                    display: flex;
                    .p-tabview-nav-container{
                        .p-tabview-nav{
                            display: block;
                            padding-right: 12px;
                            border-width:0px;
                            border-right: 1px solid $bg-30;
                            .p-tabview-ink-bar{
                                display: none;
                            }
                            li{
                                .p-tabview-nav-link{
                                    margin: 0px;
                                    border: none;
                                    padding: 10px 16px;
                                    border-radius: 4px;
                                    .p-tabview-title{
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: $text-40;
                                    }
                                    &:hover{
                                        background: $bg-20;
                                    }
                                    &:focus{
                                        box-shadow:none;
                                    }
                                }
                                &.p-highlight{
                                    .p-tabview-nav-link{
                                        background: $bg-20;
                                    }
                                }
                            }
                        }
                    }
                    .p-tabview-panels{
                        width: 100%;
                        border-right: 1px solid $bg-30;
                        padding: 0.2rem 1rem;
                        .filter-options-section{
                            flex-direction: column;
                            justify-content: space-between;
                            .filters-list{
                                min-height: 435px;
                                max-height: 435px;
                                overflow: auto;
                                padding-bottom: 1rem;
                                &::-webkit-scrollbar {
                                    width:6px;
                                }
                                &::-webkit-scrollbar-track {
                                    background: $bg-20;
                                    border-radius: 10px;
                                }
                                &::-webkit-scrollbar-thumb {
                                    background: $bg-40;
                                    border-radius: 10px;
                                }
                                &::-webkit-scrollbar-thumb:hover {
                                    background: $text-20; 
                                }
                                .sticky-search{
                                    position: sticky;
                                    top: 0px;
                                    z-index: 9;
                                    padding: 1px 0px 8px;
                                    background: $white;
                                }
                            }
                            .border-top{
                                border-top: 1px solid #EAECF0;
                                padding-top: 1rem;
                            }
                            .logout-btns{
                                padding: 7px 14px;
                                border-radius: 4px;
                                border: 1px solid $bg-40;
                                background: $white;
                                .p-button-label{
                                    font-size: 13px;
                                    font-weight: 500;
                                    color: $text-40;
                                }
                                &.click-btn{
                                    background: $primary-60;
                                    border: 1px solid $primary-60;
                                    .p-button-label{
                                        color: $white;
                                    }
                                    &:hover{
                                        background: $gray-900;
                                        border: 1px solid $gray-900;
                                    }
                                }
                                &.color-update{
                                    background: $blue-70;
                                    border: 1px solid $blue-70;
                                    &:hover{
                                        background: $text-40;
                                    }
                                }
                                &:hover{
                                    border: 1px solid $text-40;
                                    background: $text-60;
                                    .p-button-label{
                                        color: $white;
                                    }
                                }
                                &:focus{
                                    box-shadow: none;
                                }
                                &.reset-btn{
                                    &:hover{
                                        background: $primary-60;
                                        border: 1px solid $primary-60;
                                        .p-button-label{
                                            color: $white;
                                        }
                                    }
                                    .p-button-label{
                                        color: $text-40;
                                    }
                                }
                            }
                            .other-tab{
                                height: 435px;
                                overflow: auto;
                                &::-webkit-scrollbar {
                                    width:6px;
                                }
                                &::-webkit-scrollbar-track {
                                    background: $bg-20;
                                    border-radius: 10px;
                                }
                                &::-webkit-scrollbar-thumb {
                                    background: $bg-40;
                                    border-radius: 10px;
                                }
                                &::-webkit-scrollbar-thumb:hover {
                                    background: $text-20; 
                                }
                            }
                            .date-calendar{
                                .p-calendar {
                                    width: 100%;
                                    min-height: 435px;
                                }
                                .p-datepicker table td{
                                    padding: 0.3rem 0.4rem;
                                    span{
                                        font-size: 14px;
                                        font-weight: 400;
                                        width: 2.1rem;
                                        height: 2.1rem;
                                    }
                                }
                            }
                        }
                        .status-box{
                            background: $bg-20;
                            padding: 8px 12px;
                            border-radius: 6px;
                            border: 1px solid $bg-40;
                            .p-checkbox{
                                width: 18px;
                                height: 18px;
                                .p-checkbox-box{
                                    width: 18px;
                                    height: 18px;
                                    background: #F3F3F3;
                                    &:hover{
                                        border-color: $text-40;
                                    }
                                    &.p-focus{
                                        box-shadow: none;
                                    }
                                }
                                &.p-checkbox-checked{
                                    .p-checkbox-box{
                                        background:$text-40;
                                        border-color: $text-40;
                                    }
                                }
                            }
                            label{
                                font-size: 13px;
                                font-weight: 500;
                                color: $text-40;
                            }
                        }
                        .search-box{
                            padding: 9px 14px 9px 35px;
                            font-size: 14px;
                            font-weight: 400;
                            width: 100%;
                        }
                        .date-calendar{
                            .p-datepicker{
                                border: none;
                                .p-datepicker-header{
                                    border-bottom: 0px;
                                }
                            }
                        }
                        .p-checkbox{
                            width: 16px;
                            height: 16px;
                            .p-checkbox-box{
                                width: 16px;
                                height: 16px;
                                border-radius: 4px;
                                &.p-highlight{
                                    background: $text-40;
                                    border-color: $text-40;
                                }
                                &.p-focus{
                                    box-shadow: none;
                                }
                            }
                        }
                        .p-radiobutton{
                            width: 16px;
                            height: 16px;
                            .p-radiobutton-box{
                                border: 1px solid $bg-40;
                                width: 16px;
                                height: 16px;
                            }
                            &.p-radiobutton-checked{
                                .p-radiobutton-box{
                                    background: $bg-20;
                                }
                                .p-radiobutton-icon{
                                    width: 8px;
                                    height: 8px;
                                    background: $gray-700;
                                }
                            }
                        }
                        label, .label{
                            font-size: 13px;
                            font-weight: 400;
                            color: $text-40;
                            line-height: 20px;
                        }
                    }
                }
                .filters-list{
                    font-size: 12px;
                    font-weight: 400;
                    color: $text-60;
                    margin-top: 5px;
                    display: block;
                    ul{
                        margin: 0px;
                        padding: 0px;
                        li{
                            list-style: none;
                            display: inline-block;
                            margin-bottom: 10px;
                            span{
                                padding: 6px 8px;
                                border-radius: 4px;
                                border: 1px solid $bg-40;
                                background:$bg-20;
                                font-size: 12px;
                                font-weight: 500;
                                margin-right: 8px;
                                display: inline-block;
                                word-break: break-word;
                                .pi{
                                    font-size: 12px;
                                    color: $text-30;
                                    position: relative;
                                    top: 1px;
                                    margin-left: 3px;
                                    cursor: pointer;
                                    &:hover{
                                        color: $primary-60;
                                    }
                                }
                            }
                        }
                        &.other-ul{
                            li{
                                margin-left: 8px;
                                font-size: 12px;
                                font-weight: 500;
                                color: $text-60;
                                &:first-child{
                                    margin-left: 0px;
                                    font-weight: 400;
                                }
                                span{
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }
                .right-section{
                    min-height: 435px;
                    max-height: 485px;
                    overflow: auto;
                    padding-left: 10px;
                    &::-webkit-scrollbar {
                        width:6px;
                    }
                    &::-webkit-scrollbar-track {
                        background: $bg-20;
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: $bg-40;
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background: $text-20; 
                    }
                    p{
                        font-size: 12px;
                        font-weight: 400;
                        color: $text-40;
                        margin: 0px 0px 15px;
                        &.filters_p{
                            border-bottom: 1px solid #E5E5E5;
                            padding-bottom: 12px;
                            font-size: 13px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
.export-icon {
    color: $black;
    .pi-upload{
        font-size: 12px;
    }
    &:hover {
        color: $white;
        .pi-upload{
            color: $white;
        }
    }
}
.search-filter-applied {
    border: 1px solid $primary-60 !important;
}
.filter_toggle_icon {
    color: $black;
    padding: 4px;
    width: 2rem;
    border: 1px solid $text-30;
}
/* .message {
    .p-button {
        background: #9a9cef !important;
        border: 1px solid #9a9cef !important;
    }
} */
.chat-dialog .file-upload .p-fileupload-choose {
    background: #fff !important;
    border: 1px solid #EAECF0 !important;
}

