@import '../../assets/scss/variables';

.profile-page-section{
    padding: 50px 70px 20px;
    height: calc(100% - 190px);
    min-height: calc(100vh - 190px);
    position: relative;
    &:before{
        content: "";
        position: absolute;
        width: 100%;
        min-height: 190px;
        background: $border-gray;
        left: 0px;
        right: 0px;
        top: 0px;
        z-index: -1;
    }
    .profile-wrapper{
        width: 95%;
        max-width: 900px;
        margin: 0 auto;
        .profile-tabs-section{
            h1{
                font-size: 20px;
                font-weight: 500;
                color: $text-40;
                font-family: $font-secondary;
                margin: 0px 0px 15px;
            }
            .form-section{
                border-radius: 6px;
                .form-header{
                    border-radius: 6px 6px 0px 0px;
                    border: 1px solid $bg-40;
                    background: $bg-30;
                    p{
                        margin: 0px;
                        padding: 6px 20px;
                        font-size: 12px;
                        font-weight: 400;
                        color: $text-40;
                    }
                }
                .form-body{
                    padding: 20px;
                    border: 1px solid $bg-40;
                    background: $white;
                    border-radius: 0px 0px 6px 6px;
                    border-top: 0px;
                    label, .label{
                        font-size: 14px;
                        font-weight: 500;
                        color: $text-40;
                    }
                    .company-name{
                        font-size: 13px;
                        font-weight: 500;
                        color: $text-40;
                    }
                    .p-inputtext{
                        padding: 8px 14px;
                        font-size: 13px;
                        font-weight: 500;
                        color: $text-40;
                        border-radius: 6px;
                        border: 1px solid $bg-40;
                        background: $bg-20;
                    }
                    .p-dropdown{
                        padding: 8px 14px;
                        font-size: 13px;
                        font-weight: 500;
                        color: $text-40;
                        border-radius: 6px;
                        border: 1px solid $bg-40;
                        background: $bg-20;
                        .p-inputtext{
                            padding: 0px;
                            border:0px;
                        }
                        .p-dropdown-trigger{
                            width: auto;
                        }
                    }
                    .profile-upload{
                        position: relative;
                        .p-fileupload{
                            position: relative;
                            z-index: 2;
                        }
                        .p-fileupload-choose{
                            width: 86px;
                            height: 86px;
                            padding: 0px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: transparent;
                            border-radius: 4px;
                            border: 1px dashed $bg-40;
                            .p-button-label{
                                display: none;
                            }
                            .p-button-icon{
                                display: none;
                            }
                            &::before{
                                content: "\e96c";
                                font-family: primeicons;
                                font-size: 14px;
                                background: $gray-900;
                                padding: 4px;
                                border-radius: 5px;
                                box-shadow: 0px 0px 12px #fff;
                            }
                        }
                        .p-fileupload-buttonbar{
                            border: none;
                            padding: 0px;
                            background: none;
                        }
                        .p-fileupload-content{
                            border: none;
                            padding: 0px;
                            background: none; 
                        }
                        .profile-photo{
                            img{
                                width: 85px;
                                height: 85px;
                                border-radius: 4px;
                                object-fit: cover;
                            }
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            right: 0px;
                            bottom: 0px;
                        }
                    }
                    .error-message{
                        font-size: 12px;
                        font-weight: 400;
                        color: $danger-20;
                        margin: 5px 0px 0px;
                    }
                    .vertical-tabs{
                        flex-direction: row;
                        display: flex;
                        .p-tabview-nav-container{
                            min-width: 135px;
                            .p-tabview-nav-content{
                                margin-top: 3rem;
                                .p-tabview-nav{
                                    display: block;
                                    border: none;
                                    background: none;
                                    li{
                                        .p-tabview-nav-link{
                                            border: none;
                                            padding: 6px 12px;
                                            border-radius: 4px;
                                            background: transparent;
                                            margin-bottom: 10px;
                                            .p-tabview-title{
                                                font-size: 13px;
                                                font-weight: 500;
                                                color: $text-30;
                                                white-space: break-spaces;
                                                line-height: 17px;
                                            }
                                            &:focus{
                                                box-shadow:none;
                                            }
                                            &:hover{
                                                background: $bg-30;
                                                .p-tabview-title{
                                                    color: $text-40; 
                                                }
                                            }
                                        }
                                        &.p-highlight{
                                            .p-tabview-nav-link{
                                                background: transparent;
                                                .p-tabview-title{
                                                    color: $text-40;
                                                    font-weight: 600;
                                                }
                                            }
                                        }
                                        &.p-tabview-ink-bar{
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                        .p-tabview-nav-container{
                            width: 100%;
                            max-width: 260px;
                            .p-tabview-nav-content{
                                margin-top: 0rem;
                                margin-right: 10px;
                                .p-tabview-nav li{
                                    .p-tabview-nav-link{
                                        margin-bottom: 0px;
                                        padding: 6px 2px 6px 6px;
                                    }
                                }
                            }
                        }
                        .p-tabview-panels{
                            margin-left: 20px;
                            width: 100%;
                            padding: 0px;
                        }
                        .leagues-section{
                            p{
                                font-size: 13px;
                                font-weight: 500;
                                color: $text-40;
                                line-height: 21px;
                                margin: 0px 0px 8px;
                            }
                        }
                    }
                    .delete-icon{
                        cursor: pointer;
                        display: flex;
                        width: 22px;
                        height: 22px;
                        align-items: center;
                        justify-content: center;
                        border-radius: 10px;
                        position: absolute;
                        top: -6px;
                        z-index: 9;
                        margin-left: 70px;
                        background: $danger-20;
                        .pi{
                            color: $white;
                            font-size: 12px;
                        }
                        &:hover{
                            opacity: 0.9;
                        }
                    }
                    .sports-block{
                        max-height: 550px;
                        overflow: auto;
                        display: flex;
                        flex-wrap: wrap;
                        margin-right: -20px;
                        align-items: flex-start;
                        .sports-list{
                            flex: 0 204px;
                            background: $bg-20;
                            padding: 7px 12px 7px 8px;
                            border-radius: 6px;
                            border: 1px solid $bg-40;
                            margin: 0px 10px 10px 0px;
                            .p-image{
                                line-height: 0px;
                                display: block;
                            }
                            span{
                                font-size: 13px;
                                font-weight: 400;
                                color: $text-40;
                            }
                            .sports-icon img {
                                width: 20px;
                            }
                        }
                        &::-webkit-scrollbar {
                            width:6px;
                        }
                        &::-webkit-scrollbar-track {
                            background: $bg-20;
                            border-radius: 10px;
                        }
                        &::-webkit-scrollbar-thumb {
                            background: $bg-40;
                            border-radius: 10px;
                        }
                        &::-webkit-scrollbar-thumb:hover {
                            background: $text-20; 
                        }
                    }
                }
                .form-footer{
                    padding: 10px 0px;
                    text-align: end;
                    .save-button{
                        border: none;
                        border-radius: 4px;
                        background: $primary-60;
                        padding: 10px 18px;
                        &.regulator{
                            background: $re-green-70;  
                        }
                        .p-button-label{
                            font-size: 13px;
                            font-weight: 500;
                        }
                        &:hover{
                            background: $text-40;
                        }
                    }
                }
            }
            .vertical-menu{
                .p-menubar {
                    padding: 0px;
                    background: none;
                    border: none;
                    .p-menubar-root-list{
                        display: block;
                        .p-menuitem{
                            margin-bottom: 10px;
                            .p-menuitem-link{
                                font-size: 13px;
                                font-weight: 500;
                                color: $text-30;
                                white-space: break-spaces;
                                line-height: 17px;
                                padding: 6px 12px;
                                border-radius: 4px;
                                .p-menuitem-text{
                                    color: $text-30;
                                }
                                &:focus{
                                    box-shadow:none;
                                }
                                &:hover{
                                    background: $bg-30;
                                    .p-menuitem-text{
                                        color: $text-40;
                                    }
                                }
                            }
                            &.p-menuitem-active{
                                .p-menuitem-link{
                                    background: none;
                                    .p-menuitem-text{
                                        color: $text-40;
                                        font-weight: 500;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.p-dropdown-panel{
    .p-dropdown-items-wrapper{
        .p-dropdown-item{
            font-size: 14px;
            font-weight: 500;
            color: $primary-20;
        }
    }
}.password-wrapper {
    display: flex;
    align-items: center;
}
.password-input {
    flex-grow: 0.9;
    display: flex;
    align-items: center;

    .p-inputtext {
        width: 50%; 
        padding-right: 5.5rem; 
    }
}
.eye-icon {
    margin-left: -2rem; 
    cursor: pointer;
    font-size: 1rem;
    color: $text-30;
    &:hover{
        color: $text-40;
    }
}
.right-menu {
    margin-top: 2.55rem;
}
.profile-upload{
    .p-fileupload-content {
        display: none;
      }
}